import React from 'react';
import {
  FaLinkedin,
  FaGithub,
  FaDesktop,
  FaMobileAlt,
} from 'react-icons/fa';

import FelipeNovais from '../../assets/images/FelipeNovais.png';
import { ProjectButton } from '../../components/ProjectButton';

import styles from './styles.module.scss';

import php from '../../assets/images/logos/php.svg';
import lumen from '../../assets/images/logos/lumen.svg';
import javascript from '../../assets/images/logos/javascript.svg';
import typescript from '../../assets/images/logos/typescript.svg';
import react from '../../assets/images/logos/react.svg';
import nodejs from '../../assets/images/logos/nodejs.svg';
import flutter from '../../assets/images/logos/flutter.svg';
import git from '../../assets/images/logos/git.svg';
import mysql from '../../assets/images/logos/mysql.svg';

export function Home() {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.profile}>
          <img src={FelipeNovais} alt="Felipe Novais" />
          <div className={styles.info}>
            <h2>Felipe Novais</h2>
            <p>Desenvolvedor de Sistemas</p>
          </div>
        </div>
        <div className={styles.socialMediaContainer}>
          <a
            href="https://linkedin.com/in/felipe-novais-fn"
            rel="noreferrer"
            target="_blank"
          >
            <FaLinkedin />
            <p>LinkedIn</p>
          </a>
          <a
            href="https://github.com/FelipeNS"
            rel="noreferrer"
            target="_blank"
          >
            <FaGithub />
            <p>Github</p>
          </a>
        </div>
      </header>
      <main className={styles.content}>
        <div className={styles.me}>
          <div className={styles.about}>
            <p>
              {`Sou um desenvolvedor de software, focado no desenvolvimento de aplicações web.
              Tenho 24 anos de idade e iniciei na área de tecnologia no ano de 2014. 
              Atualmente trabalhando na `}
              <a href="https://amstedmaxion.com.br" target="_blank" rel="noreferrer">
                AmstedMaxion
              </a>{`, sou responsável por desenvolver
              sistemas que visam auxiliar e automatizar os fluxos de trabalho diário
              das diversas áreas da empresa. PHP é a linguagem mais utilizada no meu
              atual local de trabalho e, além de desenvolver cada
              vez mais minhas habilidades nesta linguagem, estou focado em aprimorar meus conhecimentos
              nas tecnologias React, React Native e NodeJs.`}
            </p>
            <div className={styles.technologies}>
              <h4>Conhecimento em</h4>
              <div className={styles.logos}>
                <a href="https://php.net" target="_blank" rel="noreferrer">
                  <img src={php} alt="PHP" width="48" height="32" />
                </a>
                <a href="https://lumen.laravel.com/" target="_blank" rel="noreferrer">
                  <img src={lumen} alt="Lumen" width="24" height="32" />
                </a>
                <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank" rel="noreferrer">
                  <img src={javascript} alt="Javascript" width="32" height="32" />
                </a>
                <a href="https://www.typescriptlang.org/" target="_blank" rel="noreferrer">
                  <img src={typescript} alt="Typescript" width="32" height="32" />
                </a>
                <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
                  <img src={react} alt="React" width="32" height="32" />
                </a>
                <a href="https://nodejs.org/en/" target="_blank" rel="noreferrer">
                  <img src={nodejs} alt="NodeJS" width="32" height="32" />
                </a>
                <a href="https://flutter.dev/" target="_blank" rel="noreferrer">
                  <img src={flutter} alt="Flutter" width="32" height="32" />
                </a>
                <a href="https://git-scm.com/" target="_blank" rel="noreferrer">
                  <img src={git} alt="Git" width="32" height="32" />
                </a>
                <a href="https://www.mysql.com/" target="_blank" rel="noreferrer">
                  <img src={mysql} alt="MySQL" width="32" height="32" />
                </a>
              </div>
            </div>
          </div>
          {/* <div className={styles.projects}>
            <ProjectButton 
              icon={FaDesktop}
              title="Projetos Web"
              text="Em breve alguns dos projetos de sistemas web que desenvolvi 
              ou participei do desenvolvimento"
            />
            <ProjectButton 
              icon={FaMobileAlt}
              title="Projetos Mobile"
              text="Em breve alguns dos projetos para plataformas mobile que 
              desenvolvi ou participei do desenvolvimento"
            />
          </div> */}
        </div>
      </main>
    </div>
  );
}